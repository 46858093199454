import React from 'react';
import { Layout, Menu, Breadcrumb } from 'antd';
import { UserOutlined, LaptopOutlined, NotificationOutlined, AppstoreOutlined, PieChartOutlined, SettingOutlined } from '@ant-design/icons';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import SlideComponent from './SlideComponent';
import QuestionComponent from './QuestionComponent';
import CourseComponent from './CourseComponent';
import UserComponent from './UserComponent';
import ProfileComponent from './ProfileComponent';
import NoticeComponent from './NoticeComponent';

import axios from 'axios';
const { Header, Content, Sider } = Layout;

export default class MainComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    handleLogOut = () => {
        axios({
            method: 'post',
            // headers: {
            //     'Origin': 'http://localhost:3000',
            // },
            withCredentials: true,
            url: 'https://api.renshe.xindi.xyz/api/admin/logout',
        })
            .then((response) => {
                // window.location = "http://localhost:3000/login"
                this.props.removeToken();
            })
            .catch((errorInfo) => {
                console.log(errorInfo);
            })
    }

    render() {
        return (
            <Layout>
                <Header className="header">
                    <div className="logo" />
                    <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']} style={{ float: 'right', marginLeft: 'auto' }}>
                        <Menu.Item key="1" onClick={this.handleLogOut}>退出</Menu.Item>
                    </Menu>
                </Header>
                <Layout>
                    <Sider width={200} className="site-layout-background">
                        <Menu
                            mode="inline"
                            defaultSelectedKeys={['1']}
                            defaultOpenKeys={['sub1']}
                            style={{ height: '100%', borderRight: 0 }}
                        >
                            <Menu.Item key="1" icon={<PieChartOutlined />}><Link to="/">控制面板</Link></Menu.Item>
                            <Menu.Item key="2" icon={<LaptopOutlined />}><Link to="/slide">轮播控制</Link></Menu.Item>
                            <Menu.Item key="3" icon={<LaptopOutlined />}><Link to="/notice">工作提示</Link></Menu.Item>
                            <Menu.Item key="4" icon={<LaptopOutlined />}><Link to="/course">课程管理</Link></Menu.Item>
                            <Menu.Item key="5" icon={<UserOutlined />}><Link to="/question">试题管理</Link></Menu.Item>
                            <Menu.Item key="6" icon={<UserOutlined />}><Link to="/user">用户管理</Link></Menu.Item>
                            <Menu.Item key="7" icon={<UserOutlined />}><Link to="/profile">个人配置</Link></Menu.Item>
                        </Menu>
                    </Sider>

                    <Routes>
                        <Route path="/slide" element={<SlideComponent key="1" baseUrl="https://api.renshe.xindi.xyz/api/slides" />} />
                        <Route path="/notice" element={<NoticeComponent key="2" baseUrl="https://api.renshe.xindi.xyz/api/notices" />} />
                        <Route path="/course" element={<CourseComponent key="3" baseUrl="https://api.renshe.xindi.xyz/api/courses" />} />
                        <Route path="/question" element={<QuestionComponent key="4" baseUrl="https://api.renshe.xindi.xyz/api/questions" />} />
                        <Route path="/user" element={<UserComponent key="5" baseUrl="https://api.renshe.xindi.xyz/api/users" />} />
                        <Route path="/profile" element={<ProfileComponent key="6" baseUrl="https://api.renshe.xindi.xyz/api/admin/change_password" />} />
                    </Routes>

                </Layout>
            </Layout>
        );
    }
}