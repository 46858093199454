// 引入编辑器组件
import BraftEditor from 'braft-editor'
// 引入编辑器样式
import 'braft-editor/dist/index.css'

import React, { Component } from "react";
import { Table, Space, Button, Form, Input, Modal, Layout, Breadcrumb, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const { Content } = Layout;

const axios = require('axios');

export default class SlideComponent extends Component {


    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        this.columns = [
            {
                title: '标题',
                dataIndex: 'title',
            },
            {
                title: '发布时间',
                dataIndex: 'created_at',
                render: (text, record) => {
                    let t = new Date(text);
                    return t.toLocaleDateString();
                }
            },
            {
                title: '操作',
                dateIndex: 'action',
                render: (text, record) => {
                    return (
                        <Space size="middle">
                            <a onClick={() => this.handleEdit(record)}>编辑</a>
                            <a onClick={() => this.handleDelete(record)}>删除</a>
                        </Space>
                    )
                },
            }
        ];
    }

    state = {
        selectedRowKeys: [], // Check here to configure the default column
        loading: false,
        modalTitle: 'Modal Title',
        modalVisible: false,
        modalWidth: 1000,
        // 创建一个空的editorState作为初始值
        editorState: BraftEditor.createEditorState(null),
        policyTitle: '',
        policyID: '',
        modalType: 'post',
        data: [],
        imageList: [],
        imageData: '',
        previewVisible: false,
        totalRecords: 0,
    };

    componentDidMount() {
        this.start(1);
    }

    start = (page) => {
        this.setState({ loading: true });
        // ajax request after empty completing
        axios({
            method: 'get',
            headers: {
                'Origin': 'http://localhost:3000',
            },
            withCredentials: true,
            url: page === null ? this.props.baseUrl : this.props.baseUrl + "?page=" + page
        })
            .then((response) => {

                console.log(response);

                this.setState({
                    selectedRowKeys: [],
                    loading: false,
                    data: response.data.data,
                    totalRecords: response.data.count,
                });
            })
            .catch((error) => {
                console.log(error);
            })
    };

    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };

    setModalVisible(modalVisible) {
        this.setState({ modalVisible })
    }

    setModalTitle(modalTitle) {
        this.setState({ modalTitle });
    }

    addNew = () => {
        this.setState({
            modalTitle: '新增轮播图片',
            policyTitle: '',
            modalType: 'post',
            imageData: '',
            imageList: [],
            editorState: BraftEditor.createEditorState(null),
        });
        this.setModalVisible(true);
    }

    handleTitleChange = (e) => {
        if (e.target) {
            this.setState({
                policyTitle: e.target.value
            })
        }
    }

    handleEditorChange = (editorState) => {
        this.setState({ editorState })
    }

    handleDelete = (record) => {
        let that = this;
        Modal.confirm({
            title: record.title,
            content: '确定删除此轮播图片吗？',
            onOk() {
                axios({
                    method: 'delete',
                    headers: {
                        'Origin': 'http://localhost:3000',
                    },
                    withCredentials: true,
                    url: that.props.baseUrl + "/" + record.id
                })
                    .then((response) => {
                        var data = that.state.data.filter((e) => { return e !== record });
                        that.setState({
                            data: data,
                        })
                    })
                    .catch((errorInfo) => {
                        console.log('Delete Failed: ', errorInfo);
                    })
            },
        });
    }

    handleBatchDelete = () => {
        console.log(this.state.selectedRowKeys);
        let that = this;
        Modal.confirm({
            title: '批量删除',
            content: '确定删除此轮播图片吗？',
            onOk() {
                that.state.selectedRowKeys.forEach((value) => {
                    console.log('delete id = ', value);
                    axios({
                        method: 'delete',
                        headers: {
                            'Origin': 'http://localhost:3000',
                        },
                        withCredentials: true,
                        url: that.props.baseUrl + "/" + value
                    })
                        .then((response) => {
                            var data = that.state.data.filter((e) => { return e.id !== parseInt(response.data.id) });
                            that.setState({
                                data: data,
                            })

                        })
                        .catch((errorInfo) => {
                            console.log('Delete Failed: ', errorInfo);
                        })
                })
                that.setState({
                    selectedRowKeys: [],
                })
            },
        });
    }

    handleEdit = (record) => {
        this.setState({
            modalTitle: '编辑轮播图片',
            modalType: 'patch',
            policyTitle: record.title,
            policyID: record.id,
            imageData: record.image,
            editorState: BraftEditor.createEditorState(record.content),
            imageList: [
                {
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: "https://api.renshe.xindi.xyz/static/uploads/" + record.image,
                }
            ],
        });
        this.setModalVisible(true);
    }

    onOk = () => {
        this.formRef.current.validateFields().then(values => {

            if (this.state.modalType == 'post') {
                axios({
                    method: 'post',
                    headers: {
                        'Origin': 'http://localhost:3000',
                    },
                    withCredentials: true,
                    url: this.props.baseUrl,
                    data: {
                        title: this.state.policyTitle,
                        image: this.state.imageList[0].url,
                        // content: this.state.editorState.toHTML()
                    },
                })
                    .then((response) => {
                        // console.log(response.data);
                        this.setState({
                            data: [
                                response.data.data,
                                ...this.state.data
                            ]
                        })
                        this.setModalVisible(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            } else if (this.state.modalType == 'patch') {
                axios({
                    method: 'put',
                    headers: {
                        'Origin': 'http://localhost:3000',
                    },
                    withCredentials: true,
                    url: this.props.baseUrl + "/" + this.state.policyID,
                    data: {
                        id: this.state.policyID,
                        title: this.state.policyTitle,
                        image: this.state.imageList[0].url,
                        // content: this.state.editorState.toHTML()
                    },
                })
                    .then((response) => {
                        this.setModalVisible(false);

                        var index = this.state.data.findIndex((e) => e.id === this.state.policyID);

                        if (index >= 0) {
                            var record = structuredClone(this.state.data[index]);
                            record.title = this.state.policyTitle;
                            record.image = this.state.imageData;
                            record.content = this.state.editorState.toHTML();

                            this.setState({
                                data: [
                                    ...this.state.data.slice(0, index),
                                    record,
                                    ...this.state.data.slice(index + 1)
                                ]
                            });
                        }

                    })
                    .catch((errorInfo) => {
                        console.log('update failed: ', errorInfo);
                    })
            }
        })
            .catch(errorInfo => {
                console.log('Failed: ', errorInfo);
            })
    }

    onCancel = () => {
        this.setModalVisible(false);
    }

    handleImageChange = (info) => {
        console.log('fileList = ', info.fileList);
        info.fileList.map((file) => {
            if (file.status == "done") {
                file.url = file.response.filepath;
            }
            console.log(file.url);
        });
        this.setState({
            imageList: [...info.fileList]
        });
    }

    handlePreviewCancel = () => {
        this.setState({
            previewVisible: false,
        })
    }

    handlePreview = () => {
        this.setState({
            previewVisible: true,
        })
    }

    handlePageChange = (current) => {
        console.log('current page = ', current);
        this.start(current);
    }

    render() {
        const { loading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <Layout style={{ padding: '0 24px 24px' }}>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>首页</Breadcrumb.Item>
                    <Breadcrumb.Item>轮播图片</Breadcrumb.Item>
                </Breadcrumb>
                <Content
                    className="site-layout-background"
                    style={{
                        padding: 24,
                        margin: 0,
                        minHeight: 280,
                    }}
                >
                    <div style={{ marginBottom: 16 }}>
                        <Space>
                            <Button type="primary" onClick={this.addNew}>
                                新增
                            </Button>
                            <Button type="primary" onClick={this.start} loading={loading}>
                                加载
                            </Button>
                            <Button type="primary" onClick={this.handleBatchDelete} disabled={!hasSelected} >
                                删除
                            </Button>
                            <span style={{ marginLeft: 8 }}>
                                {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                            </span>
                        </Space>
                    </div>
                    <Table rowSelection={rowSelection} columns={this.columns} dataSource={this.state.data}
                        rowKey={(record) => record.id} 
                        pagination={{
                            total: this.state.totalRecords,
                            onChange: this.handlePageChange,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                        }}/>

                    <Modal title={this.state.modalTitle}
                        centered
                        width={this.state.modalWidth}
                        visible={this.state.modalVisible}
                        okText='保存'
                        cancelText='取消'
                        destroyOnClose
                        onOk={this.onOk}
                        onCancel={this.onCancel}>
                        <Form
                            name="basic"
                            labelCol={{ span: 2 }}
                            wrapperCol={{ span: 24 }}
                            ref={this.formRef}
                        >
                            <Form.Item
                                label="标题"
                                name="title"
                                initialValue={this.state.policyTitle}
                                rules={[{ required: true, message: '请输入标题' }]} >
                                <Input onChange={this.handleTitleChange} />
                            </Form.Item>

                            <Form.Item
                                label="轮播图片"
                                name="image"
                                initialValue={this.state.imageData}
                                rules={[{ required: true, message: '请选择封面图片' }]}>
                                <Upload
                                    fileList={this.state.imageList}
                                    listType="picture-card"
                                    // beforeUpload={this.handleBeforeUpload}
                                    action={"https://api.renshe.xindi.xyz/api/upload"}
                                    onChange={this.handleImageChange}
                                    onPreview={this.handlePreview}>
                                    {this.state.imageList.length >= 1 ? null : (
                                        <div>
                                            <PlusOutlined />
                                            <div style={{ marginTop: 8 }}>Upload</div>
                                        </div>
                                    )}
                                </Upload>
                            </Form.Item>

                            {/* <Form.Item
                                label="内容"
                                name="content">
                                <div className="my-component">
                                    <BraftEditor
                                        value={this.state.editorState}
                                        onChange={this.handleEditorChange}
                                        onSave={this.submitContent}
                                    />
                                </div>
                            </Form.Item> */}
                        </Form>

                        <Modal
                            visible={this.state.previewVisible}
                            title="封面图片预览"
                            footer={null}
                            onCancel={this.handlePreviewCancel}>
                                <img alt="preview image" style={{ width: '100%' }} src={'https://api.renshe.xindi.xyz/static/uploads/' + this.state.imageData} />
                            </Modal>
                    </Modal>
                </Content>
            </Layout>
        );
    }
}