// 引入编辑器组件
import BraftEditor from 'braft-editor'
// 引入编辑器样式
import 'braft-editor/dist/index.css'

import React, { Component } from "react";
import { Table, Space, Button, Form, Input, Modal, Breadcrumb, Layout, Radio, Select, Checkbox } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { Header, Content, Sider } = Layout;
const { TextArea } = Input;

const axios = require('axios');

export default class QuestionComponent extends Component {


    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        this.columns = [
            {
                title: '标题',
                dataIndex: 'title',
            },
            {
                title: '发布时间',
                dataIndex: 'created_at',
                render: (text, record) => {
                    let t = new Date(text);
                    return t.toLocaleDateString();
                }
            },
            {
                title: '操作',
                dateIndex: 'action',
                render: (text, record) => {
                    return (
                        <Space size="middle">
                            <a onClick={() => this.handleEdit(record)}>编辑</a>
                            <a onClick={() => this.handleDelete(record)}>删除</a>
                        </Space>
                    )
                },
            }
        ];

        this.formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 2 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };
        this.formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 2 },
            },
        };
    }

    state = {
        selectedRowKeys: [], // Check here to configure the default column
        loading: false,
        modalTitle: 'Modal Title',
        modalVisible: false,
        modalWidth: 1000,
        // 创建一个空的editorState作为初始值
        editorState: BraftEditor.createEditorState(null),
        noticeTitle: '',
        noticeID: '',
        modalType: 'post',
        data: [],
        totalRecords: 0,
        questionType: 1,
        singleOptions: [],
        courses: [],
        course_id: 4,
        questionDes: '',
        optionFields: [],
        selectedAns: '',
        judgeOptions: ['正确', '错误'],
    };


    componentDidMount() {
        this.start(1);
    }

    start = (page) => {
        this.setState({ loading: true });
        // ajax request after empty completing
        axios({
            method: 'get',
            // headers: {
            //     'Origin': 'http://localhost:3000',
            // },
            withCredentials: true,
            url: page === null ? this.props.baseUrl : this.props.baseUrl + "?page=" + page,
        })
            .then((response) => {
                console.log('response = ', response.data)
                this.setState({
                    selectedRowKeys: [],
                    loading: false,
                    data: response.data.data.questions,
                    totalRecords: response.data.data.number,
                    courses: response.data.data.courses
                });
                console.log('this.state.couress = ', this.state.courses);
            })
            .catch((error) => {
                console.log(error);
            })
    };

    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };

    setModalVisible(modalVisible) {
        this.setState({ modalVisible })
    }

    setModalTitle(modalTitle) {
        this.setState({ modalTitle });
    }

    setNoticeTitle(noticeTitle) {
        this.setState({ noticeTitle })
    }

    addNew = () => {
        this.setState({
            modalTitle: '添加新试题',
            noticeTitle: '',
            modalType: 'post',
            singleOptions: [],
            optionFields: [],
            selectedAns: '',
            questionDes: '',
        });

        this.setModalVisible(true);
    }

    handleTitleChange = (e) => {
        if (e.target) {
            this.setNoticeTitle(e.target.value);
        }
    }

    handleEditorChange = (editorState) => {
        this.setState({ editorState })
    }

    handleDelete = (record) => {
        console.log('record = ', record);
        let that = this;
        Modal.confirm({
            title: record.title,
            content: '确定删除此试题吗？',
            onOk() {
                console.log(that.props.baseUrl);
                axios({
                    method: 'delete',
                    // headers: {
                    //     'Origin': 'http://localhost:3000',
                    // },
                    withCredentials: true,
                    url: that.props.baseUrl + "/" + record.id
                })
                    .then((response) => {
                        var data = that.state.data.filter((e) => { return e !== record });
                        that.setState({
                            data: data,
                        })
                    })
                    .catch((errorInfo) => {
                        console.log('Delete Failed: ', errorInfo);
                    })
            },
        });
    }

    handleBatchDelete = () => {
        console.log(this.state.selectedRowKeys);
        let that = this;
        Modal.confirm({
            title: '批量删除',
            content: '确定删除此试题吗？',
            onOk() {
                that.state.selectedRowKeys.forEach((value) => {
                    console.log('delete id = ', value);
                    axios({
                        method: 'delete',
                        // headers: {
                        //     'Origin': 'http://localhost:3000',
                        // },
                        withCredentials: true,
                        url: that.props.baseUrl + "/" + value
                    })
                        .then((response) => {
                            var data = that.state.data.filter((e) => { return e.id !== value });
                            that.setState({
                                data: data,
                            })
                        })
                        .catch((errorInfo) => {
                            console.log('Delete Failed: ', errorInfo);
                        })
                })
                that.setState({
                    selectedRowKeys: [],
                })
            },
        });
    }

    handleEdit = (record) => {
        console.log('noticeTitle = ', record.title);
        var singleOptions = []
        if (record.questionType == 1 || record.questionType == 2) {
            for (var i=0; i<record.options.length; i++) {
                singleOptions.push(String.fromCharCode(65+i));
            }
        } else if (record.questionType == 3) {
            singleOptions.push('正确');
            singleOptions.push('错误');
        }
        console.log('singleOptions = ', singleOptions);

        this.setState({
            modalTitle: '编辑试题',
            modalType: 'patch',
            noticeTitle: record.title,
            noticeID: record.id,
            
            course_id: record.course_id,
            questionType: record.questionType,
            questionDes: record.des,
            optionFields: record.options,
            singleOptions: singleOptions,
            selectedAns: record.ans,
        });
        console.log('this.state.noticeTitle = ', this.state.noticeTitle);
        this.setModalVisible(true);
    }

    onOk = () => {
        this.formRef.current.validateFields().then(values => {
            console.log('values = ', values);

            console.log('this.state.modalType = ', this.state.modalType);

            if (this.state.modalType == 'post') {
                axios({
                    method: 'post',
                    // headers: {
                    //     'Origin': 'http://localhost:3000',
                    //     // 'Content-Type': 'multipart/form-data'
                    // },
                    withCredentials: true,
                    url: this.props.baseUrl,
                    data: {
                        questionType: values.type,
                        title: values.title,
                        ans: values.ans,
                        des: values.des,
                        options: values.options,
                        course_id: this.state.course_id
                    },
                })
                    .then((response) => {
                        console.log(response.data);
                        this.setState({
                            data: [
                                response.data.data,
                                ...this.state.data
                            ],
                            totalRecords: this.state.data.length,
                        })
                        this.setModalVisible(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            } else if (this.state.modalType == 'patch') {
                axios({
                    method: 'put',
                    // headers: {
                    //     'Origin': 'http://localhost:3000',
                    // },
                    withCredentials: true,
                    url: this.props.baseUrl + "/" + this.state.noticeID,
                    data: {
                        id: this.state.noticeID,
                        questionType: values.type,
                        title: values.title,
                        ans: values.ans,
                        des: values.des,
                        options: values.options,
                        course_id: this.state.course_id
                    },
                })
                    .then((response) => {
                        this.setModalVisible(false);

                        var index = this.state.data.findIndex((e) => e.id === this.state.noticeID);

                        if (index >= 0) {
                            // var record = structuredClone(this.state.data[index]);
                            // record.title = this.state.noticeTitle;
                            // record.content = this.state.editorState.toHTML();

                            this.setState({
                                data: [
                                    ...this.state.data.slice(0, index),
                                    response.data.data,
                                    ...this.state.data.slice(index + 1)
                                ]
                            });
                        }

                    })
                    .catch((errorInfo) => {
                        console.log('update failed: ', errorInfo);
                    })
            }
        })
            .catch(errorInfo => {
                console.log('Failed: ', errorInfo);
            })
    }

    handlePageChange = (current) => {
        console.log('current = ', current);
        this.start(current);
    }

    handleQuestionTypeChanage = (e) => {
        console.log('type = ', e.target.value);
        this.setState({
            questionType: e.target.value,
        })
    }

    handleAddOption = () => {
        this.setState({
            singleOptions: [
                ...this.state.singleOptions,
                String.fromCharCode(65 + this.state.singleOptions.length)
            ]
        })
    }

    handleRemoveOption = () => {
        this.setState({
            singleOptions: [
                ...this.state.singleOptions.slice(0, this.state.singleOptions.length - 1)
            ]
        })
    }

    createOptionsItem = () => {
        console.log('type = ', this.state.questionType);
        if (this.state.questionType == 1 || this.state.questionType == 2) {
            return (
                <Form.List
                    name="options"
                    initialValue={this.state.optionFields}
                >
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <Form.Item
                                    {...(index === 0 ? this.formItemLayout : this.formItemLayoutWithOutLabel)}
                                    label={index === 0 ? '选项' : ''}
                                    required={false}
                                    key={field.key}
                                >
                                    <Form.Item
                                        {...field}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: "请输入选项内容",
                                            },
                                        ]}
                                        noStyle
                                    >
                                        <Input placeholder="passenger name" style={{ width: '80%' }} />
                                    </Form.Item>
                                    {fields.length > 1 ? (
                                        <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => { this.handleRemoveOption(); remove(field.name); }}
                                        />
                                    ) : null}
                                </Form.Item>
                            ))}
                            <Form.Item
                                {...this.formItemLayoutWithOutLabel}
                            >
                                <Button
                                    type="dashed"
                                    onClick={() => { this.handleAddOption(); add(); }}
                                    // style={{ width: '60%' }}
                                    icon={<PlusOutlined />}
                                >
                                    添加选项
                                </Button>
                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            );
        } else {
            return null;
        }
    }

    createAnswersItem = () => {
        if (this.state.questionType == 1) {
            return (
                <Form.Item
                    label="答案"
                    name="ans"
                    initialValue={this.state.selectedAns}
                >
                    <Radio.Group options={this.state.singleOptions}>
                    </Radio.Group>
                </Form.Item>
            );
        } else if (this.state.questionType == 2) {
            return (
                <Form.Item
                    label="答案"
                    name="ans"
                    initialValue={this.state.selectedAns}
                >
                    <Checkbox.Group options={this.state.singleOptions}></Checkbox.Group>
                </Form.Item>
            )
        } else if (this.state.questionType == 3) {
            return (
                <Form.Item
                    label="答案"
                    name="ans"
                    initialValue={this.state.selectedAns}
                >
                    <Radio.Group options={this.state.judgeOptions}>
                    </Radio.Group>
                </Form.Item>
            );
        } else {
            return (
                <Form.Item
                    label="答案"
                    name="ans"
                    initialValue={this.state.selectedAns}
                >
                    <TextArea rows={4} />
                </Form.Item>
            );
        }
    }

    handelCourseSelect = (e) => {
        this.setState({
            course_id: e
        })
    }

    render() {
        const { loading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <Layout style={{ padding: '0 24px 24px' }}>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>首页</Breadcrumb.Item>
                    <Breadcrumb.Item>试题管理</Breadcrumb.Item>
                </Breadcrumb>
                <Content
                    className="site-layout-background"
                    style={{
                        padding: 24,
                        margin: 0,
                        minHeight: 280,
                    }}
                >
                    <div style={{ marginBottom: 16 }}>
                        <Space>
                            <Button type="primary" onClick={this.addNew}>
                                新增
                            </Button>
                            <Button type="primary" onClick={this.start} loading={loading}>
                                加载
                            </Button>
                            <Button type="primary" onClick={this.handleBatchDelete} disabled={!hasSelected} >
                                删除
                            </Button>
                            <span style={{ marginLeft: 8 }}>
                                {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                            </span>
                        </Space>
                    </div>
                    <Table rowSelection={rowSelection} columns={this.columns} dataSource={this.state.data}
                        rowKey={(record) => record.id}
                        pagination={{
                            total: this.state.totalRecords,
                            onChange: this.handlePageChange,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                        }} />

                    <Modal title={this.state.modalTitle}
                        centered
                        width={this.state.modalWidth}
                        visible={this.state.modalVisible}
                        okText='保存'
                        cancelText='取消'
                        destroyOnClose
                        onOk={() => this.onOk()}
                        onCancel={() => this.setModalVisible(false)}>
                        <Form
                            name="basic"
                            labelCol={{ span: 2 }}
                            wrapperCol={{ span: 24 }}
                            ref={this.formRef}
                        >
                            <Form.Item
                                label="分类"
                                name="course"
                                initialValue={this.state.course_id}
                                // rules={[
                                //     {
                                //         require: true,
                                //         message: '请选择课程分类'
                                //     },
                                // ]}
                                >
                                <Select options={this.state.courses} onSelect={this.handelCourseSelect}/>
                            </Form.Item>
                            <Form.Item
                                label="类型"
                                name="type"
                                initialValue={this.state.questionType}>
                                <Radio.Group onChange={this.handleQuestionTypeChanage}>
                                    <Radio value={1}>单选</Radio>
                                    <Radio value={2}>多选</Radio>
                                    <Radio value={3}>判断</Radio>
                                    <Radio value={4}>填空</Radio>
                                    <Radio value={5}>简答</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="标题"
                                name="title"
                                initialValue={this.state.noticeTitle}
                                rules={[{ required: true, message: '请输入标题' }]} >
                                <TextArea onChange={this.handleTitleChange} rows={3} />
                            </Form.Item>

                            {this.createOptionsItem()}
                            {this.createAnswersItem()}

                            <Form.Item
                                label="解析"
                                name="des"
                                initialValue={this.state.questionDes}
                            >
                                <TextArea rows={4} />
                            </Form.Item>
                        </Form>
                    </Modal>
                </Content>
            </Layout>
        );
    }
}