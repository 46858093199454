import React from 'react';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import loginImg from './login.png'
import './LoginComponent.css'
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import sha256 from 'crypto-js/sha256';

export default class LoginComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    handleSubmit = (e) => {
        console.log('md5 = ', sha256(e.password).toString())

        axios({
            method: 'post',
            // headers: {
            //     'Origin': 'http://localhost:3000',
            // },
            withCredentials: true,
            url: 'https://api.renshe.xindi.xyz/api/admin/login',
            data: {
                username: e.name,
                password: sha256(e.password).toString()
            }
        })
            .then((response) => {
                console.log(response);
                if (response.data.hasLoggedIn) {
                    // window.location = 'http://localhost:3000'
                    this.props.setToken(e.name);
                } else {
                    message.error("用户名或密码错误！");
                }
            })
            .catch((errorInfo) => {
                console.log(errorInfo);
            })
    }

    render() {
        return (
            <div className={"lContainer"}>
                <div className="lItem">
                    <div className="loginImage">
                        <img src={loginImg} width="300" style={{ position: 'relative' }} alt="login" />
                    </div>
                    <div className="loginForm">
                        <h2>登录</h2>
                        <Form onFinish={this.handleSubmit} className="login-form">
                            <Form.Item
                                label="用户名"
                                name="name"
                                rules={[{ required: true, message: '请输入用户名' }]}
                            >
                                <Input
                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                    placeholder="用户名"
                                />
                            </Form.Item>
                            <Form.Item
                                label="密&nbsp;&nbsp;&nbsp;&nbsp;码"
                                name="password"
                                rules={[{ required: true, message: '请输入密码' }]}
                            >
                                <Input
                                    prefix={<LockOutlined className='site-form-item-icon' />}
                                    type="password"
                                    placeholder="密码"
                                />
                            </Form.Item>
                            <Form.Item
                                name="remember"
                                valuePropName="checked">
                                <Checkbox>记住我</Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="login-form-button"
                                >
                                    登录
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                <div className="footer">
                    <a href="" target="_blank" rel="noopener noreferrer" className="footerLink">Powered by React</a>
                </div>
            </div>
        );
    }
};