import logo from './logo.svg';
import './App.css';
import 'antd/dist/antd.min.css';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import MainComponent from './components/MainComponent';
import LoginComponent from './components/LoginComponent';

export default class App extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    hasLoggedIn: false,
  }

  setToken = (token) => {
    sessionStorage.setItem('token', token);
    this.setState({
      hasLoggedIn: true
    })
  }

  getToken = () => {
    var token = sessionStorage.getItem('token');
    console.log('get token = ', token);
    return token != undefined && token != null;
  }

  removeToken = () => {
    sessionStorage.removeItem('token');
    this.setState({
      hasLoggedIn: false
    })
  }

  componentDidMount = () => {
    if (this.getToken()) {
      this.setState({
        hasLoggedIn: true
      });
    }
  }

  render() {
    return (
      <Router>
        {this.state.hasLoggedIn ? <MainComponent removeToken={this.removeToken} /> : <LoginComponent setToken={this.setToken} />}
      </Router>
    );
  }
}
