import React from "react";
import { Button, Form, Input, Message, Layout, Breadcrumb, message } from 'antd';
import axios from "axios";
import sha256 from "crypto-js/sha256";

const { Content } = Layout;

export default class ProfileComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {

    }

    onFinish = (values) => {
        console.log('onFinish values = ', values);

        axios({
            method: 'post',
            // headers: {
            //     'Origin': 'http://localhost:3000',
            // },
            withCredentials: true,
            url: this.props.baseUrl,
            data: {
                oldpassword: sha256(values.oldpassword).toString(),
                password: sha256(values.password).toString(),
                password2: sha256(values.password2).toString()
            }
        })
            .then((response) => {
                // window.location = "http://localhost:3000/login"
                message.info(response.data.msg);
            })
            .catch((errorInfo) => {
                console.log(errorInfo);
            })
    }

    onFinishFailed = (values) => {
        console.log('onFinished values = ', values);
    }

    render() {
        return (
            <Layout style={{ padding: '0 24px 24px' }}>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>首页</Breadcrumb.Item>
                    <Breadcrumb.Item>课程管理</Breadcrumb.Item>
                </Breadcrumb>
                <Content
                    className="site-layout-background"
                    style={{
                        padding: 24,
                        margin: 0,
                        minHeight: 280,
                    }}
                >
                    {/* <div style={{ marginBottom: 16 }}>
                        <Space>
                            <Button type="primary">
                                新增
                            </Button>
                            <Button type="primary">
                                加载
                            </Button>
                            <Button type="primary">
                                删除
                            </Button>
                        </Space>
                    </div> */}

                    <div style={{ marginBottom: 16 }}>
                        <Form
                            name="basic"
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 8 }}
                            initialValues={{ remember: true }}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="请输入旧密码"
                                name="oldpassword"
                                rules={[{ required: true, message: '请输入旧密码' }]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item
                                label="请输入新密码"
                                name="password"
                                rules={[{ required: true, message: '请输入新密码' }]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item
                                label="请确认新密码"
                                name="password2"
                                rules={[{ required: true, message: '请再次输入新密码' }]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                                <Button type="primary" htmlType="submit">
                                    提交
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Content>
            </Layout>
        );
    }
}